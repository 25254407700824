<template>
	<div class="loaded page-review">
		<h2 class="ul">Review Disclosure</h2>
		<div class="review-box mb-4">
			<div class="edit"><router-link to="/step1">edit answers</router-link></div>
			<h3>Case Background</h3>
			<div v-for="q in $store.state.questions.background" :key="q.questionId">
				<div class="q">{{q.questionText}}</div>
				<div class="a">{{getResponse(q.response)}}</div>
			</div>
		</div>
		<div class="review-box mb-4">
			<div class="edit"><router-link to="/step2">edit answers</router-link></div>
			<h3>Case Details</h3>
			<div v-for="q in $store.state.questions.details" :key="q.questionId">
				<div class="q">{{q.questionText}}</div>
				<div class="a">{{getResponse(q.response)}}</div>
			</div>
		</div>
		<div v-if="$store.state.org.hasAdditionalQuestions" class="review-box mb-4">
			<div class="edit"><router-link to="/step3">edit answers</router-link></div>
			<h3>Additional Information</h3>
			<div v-for="q in $store.state.questions.additional" :key="q.questionId">
				<div class="q">{{q.questionText}}</div>
				<div class="a">{{getResponse(q.response)}}</div>
			</div>
		</div>
		<div class="review-box mb-4">
			<h3>Add Personal Details to help with the report</h3>
			<p>Providing your personal details is optional. If you wish to be contacted about this report, please add your details here. </p>
			<b-form>
				<b-form-group
					label="Name"
					label-for="name"
					label-class="optional"
				>
					<b-form-input id="name" ref="name" v-model="name"></b-form-input>
				</b-form-group>
				<b-form-group
					label="Email"
					label-for="Email"
					label-class="optional"
				>
					<b-form-input id="Email" v-model="email" type="email" name="email" autocomplete="email" />
				</b-form-group>
				<b-form-group
					label="Phone Number"
					label-for="mobile"
					label-class="optional"
				>
					<b-form-input id="mobile" ref="mobile" v-model="mobile" type="number"></b-form-input>
					<b-form-checkbox v-model="anon" class="mt-4 fs-15">Withhold my contact details from {{orgName}} (PKF may contact you if necessary)</b-form-checkbox>
				</b-form-group>
			</b-form>
		</div>
		<div class="d-flex">
			<btn-back />
			<!-- <btn-save /> -->
			<b-button @click="submit">Submit</b-button> 
		</div>
	</div>
</template>

<script>
import {sanitize} from 'dompurify';
import {canCreatePassword, hasEmail} from "@/mixins"
export default {
	name: 'Review',
	mixins: [canCreatePassword, hasEmail],
	computed: {
		prevStep() {
			return this.$store.state.org.hasAdditionalQuestions ? 'step3' : 'step2' 
		},
		name: {
			get() {
				return this.$store.state.login.name
			},
			set(name) {
				this.$store.commit('login/set', {name})
			}
		},
		mobile: {
			get() {
				return this.$store.state.login.mobile
			},
			set(mobile) {
				this.$store.commit('login/set', {mobile})
			}
		},
		anon: {
			get() {
				return this.$store.state.login.anon
			},
			set(anon) {
				this.$store.commit('login/set', {anon})
			}
		},
	},
	created () {
		this.$store.commit('steps/markAsVisited', '/' + this.prevStep)
		this.$store.commit('steps/markAsVisited', '/review')
	},
	methods: {
		submit() {
			console.log('submit', this.state);
			// set login state to submit for extra stuff in modal and isSaveOnly = false in store.save
			this.$store.commit('login/clean')
			this.$store.commit('login/set', {state: 'submit'})
			// submit is already saved
			if (this.$store.state.saved) this.$store.dispatch('save')
			// launch modal alwyas for download report
			this.createPassword()
			// if (!this.$store.state.saved) {
			// 	// launches modal through canCreatePassword mixin
			// 	this.createPassword()
			// } else {
			// 	this.$store.dispatch('save')
			// 	this.$router.push('/thanks')
			// }
			// console.log('this.$store.getters.isMobile', this.$store.getters.isMobile);
		},
		// getResponse(r) {
		// 	if (!r) return
		// 	// r = sanitize(r)
		// 	console.log('getREsponse', r);
		// 	return Array.isArray(r) 
		// 		? r.join('<br>') 
		// 		: r.replaceAll 
		// 			? r.replaceAll('\n', '<br>')
		// 			: r
		// },
		getResponse(r) {
			if (!r) return
			// r = sanitize(r)
			console.log('getREsponse', r);
			return Array.isArray(r) 
				? r.join('\n') 
				: r
		}
	},
	metaInfo: {
		title: 'Review Report',
	}
}
</script>

<style lang="scss">
.page-review{
	.review-box{
		padding: 36px 31px;
		background: white;
		.edit{
			right: 40px;
			font-size: 0.8em;
			float: right;
			a:after{
				font-family: none;
				margin-left: 5px;
				content: '\270E';
				transform: scaleX(-1);
				display: inline-block;
			}
		}
		
		@extend %full-width-xs;
		@include media-breakpoint-down(xs) {
			padding: 1rem;
			background: $secondary;
		}
	}
	.q{
		color: $primary;
		margin-bottom: .5em;
	}
	.a{
		margin-bottom: 2.5em;
		white-space: pre;
	}
} 
</style>